import type {
  BeginCheckoutArgs,
  FunnelGroup,
  Ga4Product,
  Product,
  SelectItemArgs,
  TrackAddToCartArgs,
  TrackFunnelFailArgs,
  TrackPurchaseArgs,
  TrackRemoveFromCartArgs,
  TrackViewItemArgs,
  ViewItemListArgs,
} from "./interfaces";

import type { SiteVersion } from "./enums";

import {
  AgreementType,
  ExpressCheckoutOption,
  IdentifyState,
  IdentifyType,
  OrderCompanySelectorState,
  Salesflow,
} from "./enums";

import ga4tracking from "./ga4-ecommerce-tracking";

export { SiteVersion };
export {
  AgreementType,
  ExpressCheckoutOption,
  OrderCompanySelectorState,
  IdentifyState,
  IdentifyType,
  Salesflow,
};
export { Product, Ga4Product };
export { ga4tracking };

export async function trackProductList(
  productList: Product[],
  listName: string,
  agreementType: AgreementType,
  salesflow: Salesflow,
  isOpenPages: boolean,
  isLoggedIn: boolean
): Promise<void> {
  if (productList.length > 0) {
    ga4tracking.trackProductList({
      products: productList.map(toGa4Product),
      listName,
      pageSegment: isOpenPages ? "open_pages" : "mybusiness",
      isLoggedIn,
      agreementType,
    });
  }
}

export async function trackViewItemList({
  products,
  listName,
  agreementType,
  salesflow,
  isOpenPages,
  isLoggedIn,
}: ViewItemListArgs): Promise<void> {
  if (products.length > 0) {
    ga4tracking.trackProductList({
      products: products.map(toGa4Product),
      listName,
      pageSegment: isOpenPages ? "open_pages" : "mybusiness",
      isLoggedIn,
      agreementType,
    });
  }
}

export async function trackProductClick(
  product: Product,
  listName: string,
  agreementType: AgreementType,
  salesflow: Salesflow = Salesflow.MB,
  isOpenPages = false,
  isLoggedIn: boolean,
  productIndex: number
): Promise<void> {
  ga4tracking.trackSelectItem({
    product: toGa4Product(product),
    listName,
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    productIndex,
    agreementType,
  });
}

export async function trackSelectItem({
  product,
  listName,
  agreementType,
  salesflow,
  isOpenPages,
  isLoggedIn,
  productIndex,
}: SelectItemArgs): Promise<void> {
  ga4tracking.trackSelectItem({
    product: toGa4Product(product),
    listName,
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    productIndex,
    agreementType,
  });
}

export async function trackViewItem({
  agreementType,
  isLoggedIn,
  isOpenPages,
  listName,
  product,
  salesflow,
  totalPrice,
}: TrackViewItemArgs): Promise<void> {
  ga4tracking.trackViewItem({
    listName,
    product: toGa4Product(product),
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    totalPrice: Number(totalPrice),
    agreementType,
  });
}

export async function trackFunnelFail({
  isLoggedIn,
  isOpenPages,
  errorMessage,
  totalPrice,
  agreementType,
}: TrackFunnelFailArgs): Promise<void> {
  ga4tracking.trackFunnelFail({
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    errorMessage,
    totalPrice: totalPrice ? Number(totalPrice) : undefined,
    agreementType,
  });
}

export async function trackBeginCheckout({
  agreementType,
  isLoggedIn = false,
  isOpenPages = false,
  listName,
  products,
  salesflow = Salesflow.MB,
  stepLabel,
  stepNumber,
  totalPrice,
}: BeginCheckoutArgs): Promise<void> {
  ga4tracking.trackBeginCheckout({
    listName,
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    products: products.map(toGa4Product),
    stepLabel,
    stepNumber: Number(stepNumber),
    totalPrice: Number(totalPrice),
    agreementType,
  });
}

export async function trackAddToCart({
  agreementType,
  isLoggedIn = false,
  isOpenPages = false,
  listName,
  product,
  salesflow,
  totalPrice,
}: TrackAddToCartArgs): Promise<void> {
  ga4tracking.trackAddToCart({
    listName,
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    products: [toGa4Product(product)],
    totalPrice: Number(totalPrice),
    agreementType,
  });
}

export async function trackPurchase({
  agreementType,
  isLoggedIn = false,
  isOpenPages = false,
  listName,
  numberOfBillingAccounts,
  orderId,
  productList,
  salesflow = Salesflow.MB,
  totalPrice,
}: TrackPurchaseArgs): Promise<void> {
  ga4tracking.trackPurchase({
    products: productList.map(toGa4Product),
    listName,
    orderId,
    totalPrice: Number(totalPrice),
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    agreementType,
  });
}

export async function trackRemoveFromCart({
  agreementType,
  isLoggedIn = false,
  isOpenPages = false,
  listName,
  product,
  totalPrice,
}: TrackRemoveFromCartArgs): Promise<void> {
  ga4tracking.trackRemoveFromCart({
    products: [toGa4Product(product)],
    listName,
    totalPrice: Number(totalPrice),
    isLoggedIn,
    pageSegment: isOpenPages ? "open_pages" : "mybusiness",
    agreementType,
  });
}

export async function trackLoginEvent(): Promise<void> {
  ga4tracking.trackLoginInitiate({
    isLoggedIn: false,
    pageSegment: "open_pages",
    agreementType: AgreementType.SA,
    siteVersion: "b2b_order",
    loginTarget: "mybusiness",
    stepLabel: "login_initiate",
  });
}

export async function trackRegisterEvent(): Promise<void> {
  //TODO: Needs GA4 spec from Sara
}

export async function trackIdentifyEvent(state: IdentifyState, type: IdentifyType): Promise<void> {
  //TODO: Needs GA4 spec from Sara
}

export async function trackCheckoutEvent(): Promise<void> {
  //TODO: Needs GA4 spec from Sara
}

export async function trackExpressCheckoutEvent(stepOption: ExpressCheckoutOption) {
  ga4tracking.trackExpressCheckout({
    isLoggedIn: false,
    pageSegment: "open_pages",
    agreementType: AgreementType.SA,
    siteVersion: "b2b_order",
    stepOption,
    stepLabel: "express_checkout",
    funnelGroup: "broadband",
  });
}

export async function trackOrderCompanySelectorEvent(stepLabel: OrderCompanySelectorState) {
  ga4tracking.trackOrderCompanySelector({
    isLoggedIn: false,
    pageSegment: "open_pages",
    agreementType: AgreementType.SA,
    siteVersion: "b2b_order",
    stepLabel,
    funnelGroup: "broadband",
  });
}

export async function trackLinkClickEvent(): Promise<void> {
  //TODO: Needs GA4 spec from Sara
}

export async function trackInitiateSalesflow(
  isLoggedIn: boolean,
  openPages: boolean,
  agreementType: AgreementType,
  siteVersion: SiteVersion = "mc_online",
  funnelGroup?: FunnelGroup
): Promise<void> {
  ga4tracking.trackInitiateSalesflow({
    isLoggedIn,
    pageSegment: openPages ? "open_pages" : "mybusiness",
    agreementType: agreementType,
    siteVersion: siteVersion,
    funnelGroup: funnelGroup,
  });
}

function toGa4Product(product: Product): Ga4Product {
  return {
    ...product,
    price: Number(product.price),
    recurringPrice: Number(product.recurringPrice),
    onetimePrice: Number(product.onetimePrice),
    quantity: Number(product.quantity),
    discount: product.discount ? Number(product.discount) : undefined,
  };
}
